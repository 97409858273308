import React from "react";
import { Helmet as ReactHelmet } from "react-helmet";
import {Header,  OurConditions,  AboutBlock, Footer, SliderBlock} from "../../templates";
import {useLang} from "../../../helpers/context/LanguageProvider";
import { MainBanner } from "../../organisms";


const MainPage = () => {
  const {language, translate} = useLang();
  return (
    <>
      <ReactHelmet>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#ffffff" />
        <meta name="KomandaPL" content={translate.mainPage.banner.title[language.name]} />
        <meta name="description" content={translate.seo.description[language.name]} />
        <meta name="keywords" content={translate.seo.keywords[language.name]} />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
        <meta name="robots" content="index,follow" />
        <link rel="stylesheet" type="text/css" charSet="UTF-8" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css" />
        <title>Komanda praca</title>
      </ReactHelmet>
      <div>
        <div>
          <section className="header--section">
              <Header />
          </section>
          <section className="working-with-us--section">
            <MainBanner />
          </section>
          <section className="our-conditions--section">
            <OurConditions />
          </section>
          <section className="workers--section">
            <SliderBlock />
          </section>
          <section className="about--section">
            <AboutBlock title={translate.mainPage.about.title[language.name]} texts={translate.mainPage.about.text[language.name]}
                        image={require('../../../assets/images/building1.png').default}
                        isButton={true}
                        buttonText={translate.mainPage.about.buttonText[language.name]}
            />
          </section>
          <section className="footer--section">
            <Footer />
          </section>
        </div>
      </div>
    </>


  )
}

export default MainPage;